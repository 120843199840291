* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  box-sizing: border-box;
}

.flex-row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
}

.typed-cursor {
  color: #8881ca;
}

.not-displayed {
  display: none !important;
}